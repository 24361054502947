@import "../../styles/variables";

.wrap {
    display: flex;
}

.signup {
    display: none !important;

    @media only screen and (max-width: 800px) {
        display: block !important;
        z-index: 100 !important;
        width: 100%;
        text-align: center;
        position: fixed;
        top: 55px;
        font-size: 24px;
        padding: 10px;
        background-color: $pink;
        opacity: 100%;

        a {
            text-decoration: none;
            color: white;
            font-family: Arial;
        }
    }
}
