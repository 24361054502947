$green: #8dceca;
$bluegreen: #00bcb5;
$pink: #fb9590;
$orange:#ffa70f;
$error: #cc0000;
$dbldkgrey: #505050;
$dkgrey: #7d7d7d;
$dkmedgrey:#959595;
$medgrey: #c2c2c2;
$ltgrey:#ebebeb;
$xltgrey:#f8f8f8;
$cream: #f5f5f0;

$primary: $green;
$secondary: $pink;
$tertiary: #d90263;

$primary-color: $primary;
$secondary-color: $secondary;
$tertiary-color: $tertiary;

$border-rad: 3px;

$break-xsmall: 321px;
$break-small: 480px;
$break-mini-tablet: 602px;
$break-medium: 780px;
$break-landscape: 1030px;
$break-laptop: 1450px;

$script: 'Lobster', cursive;
$sans-font: 'Lato','roboto', sans-serif;
$type: 'Cabrito W01 Nml Lt';
$helvetica: 'Helvetica Neue LT W01_41488878', sans-serif;
$serif-font: Georgia, serif;
